import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getPagination = (page: number, size: number) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size - 1 : size - 1;

  return { from, to };
};

export const transformLanguage = (language: string) => {
  switch (language) {
    case "japanese": {
      return <img src="/flag-jp.webp" alt="Japanase" className="h-4 rounded" />;
    }
    case "korean": {
      return <img src="/flag-kr.webp" alt="Korean" className="h-4 rounded" />;
    }
    case "chinese": {
      return <img src="/flag-cn.webp" alt="Chinese" className="h-4 rounded " />;
    }
    default: {
      return <div />;
    }
  }
};
